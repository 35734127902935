import $ from 'jquery';
import '@universityofwarwick/id7/js/bootstrap-modal.jquery';

const template = document.createElement('template');
template.innerHTML = `
<style>
    @import '/assets/style.css';
</style>
<a class="btn triggerButton" id="addTimeslotButton"></a>

<div class="modal fade" tabindex="-1" aria-labelledby="confirmDeleteModal" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><span class="title"></span></h5>
      </div>
      <div class="modal-body" id="timeslotBody">
        <span class="message">Please wait loading</span>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn cancelButton"></button>
      </div>
    </div>
  </div>
</div>`;


const addTimeslot = (
  time,
  date,
  csrfName,
  csrfValue,
  productId,
  quantity,
  modal,
  timeslotId,
  productOptions,
  selectedLocationId,
  selectedEventDuration,
) => {
  const formData = new FormData();
  formData.append(csrfName, csrfValue);
  formData.append('time', time);
  formData.append('date', date);
  formData.append('productId', productId);
  formData.append('quantity', quantity);
  formData.append('productOptions', productOptions);
  formData.append('selectedLocationId', selectedLocationId);
  formData.append('selectedEventDuration', selectedEventDuration);

  const data = new URLSearchParams();
  for (const pair of formData) {
    data.append(pair[0], pair[1]);
  }

  fetch('/ajax/basket/timeslot/add', {
    method: 'post',
    body: data,
  }).then((response) => {
    if (response.ok) {
      return response.text();
    }
    alert('There was a problem selecting this timeslot, please try again');
    return null;
  }).then((html) => {
    $(modal).modal('hide');
    document.getElementById('basketSummary').innerHTML = html;
    const dialog = document.getElementById('basketDialog');
    if (dialog != null) {
      dialog.showModal();
      document.querySelector('[data-close-modal]').addEventListener('click', () => {
        dialog.close();
      });
    }
  }).catch((err) => {
    console.log(err);
    alert('There was a problem selecting this timeslot, please try again');
  });
};

const createAndSubmitForm = (action, inputs) => {
  const $form = $('<form>', { method: 'POST', action });

  inputs.forEach(([name, value]) => {
    $('<input>', {
      type: 'hidden',
      name,
      value,
    }).appendTo($form);
  });

  $form.appendTo('body').submit();
};

const timeslotExpiredCallbackHandler = (
  time,
  selectedDate,
  csrfName,
  csrfValue,
  productId,
  quantity,
  modal,
  timeslotId,
  productOptions,
  selectedLocationId,
  selectedEventDuration,
) => {
  createAndSubmitForm(
    '/timeslot/expiry/update',
    [
      [csrfName, csrfValue],
      ['datetime', `${selectedDate} ${time}`],
      ['timeslotId', timeslotId],
      ['productId', productId],
      ['quantity', quantity],
      ['selectedLocationId', selectedLocationId],
      ['selectedEventDuration', selectedEventDuration],
    ],
  );
};

const replaceTimeslotCallbackHandler = (
  time,
  selectedDate,
  csrfName,
  csrfValue,
  productId,
  quantity,
  modal,
  timeslotId,
  productOptions,
  selectedLocationId,
  selectedEventDuration,
) => {
  createAndSubmitForm(
    '/timeslot/update',
    [
      [csrfName, csrfValue],
      ['datetime', `${selectedDate} ${time}`],
      ['timeslotId', timeslotId],
      ['productId', productId],
      ['quantity', quantity],
      ['selectedLocationId', selectedLocationId],
      ['selectedEventDuration', selectedEventDuration],
    ],
  );
};

const callbackHandlers = {
  addTimeslot,
  timeslotExpiredCallbackHandler,
  replaceTimeslotCallbackHandler,
};

function loadTimeslot(
  csrfName,
  csrfValue,
  productId,
  quantity,
  modal,
  date,
  callback,
  timeslotId,
  productOptions,
  selectedLocationId,
  selectedEventDuration,
) {
  const $modalBody = $(modal).find('#timeslotBody');
  $modalBody.html('Loading please wait...');
  const data = new URLSearchParams();
  data.append('date', date ?? '');
  data.append(csrfName, csrfValue);
  data.append('timeslotId', timeslotId ?? '');
  data.append('selectedLocationId', selectedLocationId || '');
  data.append('selectedEventDuration', selectedEventDuration || '');
  fetch('/ajax/basket/timeslots', {
    method: 'post',
    body: data,
  }).then((response) => {
    if (response.ok) {
      return response.text();
    }
    alert('There was a problem loading the timeslots, please try again');
    return null;
  }).then((htmlResponse) => {
    $modalBody.html(htmlResponse);

    $modalBody.find('.timeslot').toArray().map($).forEach(($timeslotOption) => {
      $timeslotOption.on('click', (timeslotOptionClickEvent) => {
        const selectedDate = $modalBody.find('#timeslotDate').val();
        callback(
          timeslotOptionClickEvent.target.dataset.date,
          selectedDate,
          csrfName,
          csrfValue,
          productId,
          quantity,
          modal,
          timeslotId,
          productOptions,
          selectedLocationId,
          selectedEventDuration,
        );
      });
    });

    const $dateInput = $modalBody.find('#timeslotDate');
    const $locationPicker = $modalBody.find('.basket-timeslot-dialog-location-picker.singleSelect-pillbox');
    const $eventDurationPicker = $modalBody.find('.basket-event-duration-picker');

    // ensures the date picker is reinitialised after the modal content is reloaded
    $locationPicker.select2({
      maximumSelectionLength: 1,
      placeholder: 'Type in the name of your room or building to filter the list...',
      allowClear: true,
    });

    $dateInput.on('change', (dateInputChangeEvent) => {
      loadTimeslot(
        csrfName,
        csrfValue,
        productId,
        quantity,
        modal,
        dateInputChangeEvent.target.value,
        callback,
        timeslotId,
        productOptions,
        $locationPicker.val(),
        $eventDurationPicker.val(),
      );
    });

    $locationPicker.on('change', (locationPickerChangeEvent) => {
      // the select2 dropdown can sometimes be dangling open after a selection is made, this closes it explicitly
      $locationPicker.select2('destroy');
      loadTimeslot(
        csrfName,
        csrfValue,
        productId,
        quantity,
        modal,
        $dateInput.val(),
        callback,
        timeslotId,
        productOptions,
        locationPickerChangeEvent.target.value,
        $eventDurationPicker.val(),
      );
    });

    $eventDurationPicker.on('change', (eventDurationPickerChangeEvent) => {
      loadTimeslot(
        csrfName,
        csrfValue,
        productId,
        quantity,
        modal,
        $dateInput.val(),
        callback,
        timeslotId,
        productOptions,
        $locationPicker.val(),
        eventDurationPickerChangeEvent.target.value,
      );
    });

    /**
     * focus on the date field when modal content is reloaded, allows user to use keyboard to select date straight away
     */
    $dateInput.focus();

    $modalBody.find('[data-toggle="tooltip"]').tooltip();
  }).catch((err) => {
    console.log(err);
    alert('There was a problem loading the timeslots, please try again');
  });
}


class ConfirmDialog extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    const modalId = this.getAttribute('modalId') ?? 'confirmModal';
    const triggerButtonId = `triggerButton-${modalId}`;
    this.shadowRoot.querySelector('.modal').setAttribute('id', modalId);
    this.shadowRoot.querySelector('.triggerButton').setAttribute('id', triggerButtonId);
    this.shadowRoot.querySelector(`#${triggerButtonId}`).innerHTML = this.getAttribute('triggerButtonLabel') ?? 'Submit';
    this.shadowRoot.querySelector('.cancelButton').innerHTML = this.getAttribute('cancelButtonLabel') ?? 'Close';
    this.shadowRoot.querySelector('.title').textContent = this.getAttribute('title') ?? 'Confirm';
    const disabled = (this.getAttribute('disabled') && this.getAttribute('disabled') === 'true') ?? false;

    const triggerBtnClass = (this.getAttribute('triggerBtnClass') ?? 'btn-primary').split(' ');
    const confirmBtnClass = (this.getAttribute('confirmBtnClass') ?? 'btn-default').split(' ');
    const cancelBtnClass = (this.getAttribute('cancelBtnClass') ?? 'btn-default').split(' ');

    this.shadowRoot.querySelector('.triggerButton').classList.add(...triggerBtnClass);
    this.shadowRoot.querySelector('.cancelButton').classList.add(...cancelBtnClass);

    const myModal = $(this.shadowRoot.getElementById(modalId)).modal({ show: false });

    if (disabled) {
      this.shadowRoot.querySelector(`#${triggerButtonId}`).setAttribute('disabled', 'true');
    } else {
      this.shadowRoot.querySelector(`#${triggerButtonId}`).addEventListener('click', () => {
        $(myModal).modal('show');
        const params = this.getAttributeNames().filter(e => e.startsWith('productoptions'));
        const ids = params.map(e => this.getAttribute(e)).join(',');

        loadTimeslot(
          this.getAttribute('csrfName'),
          this.getAttribute('csrfValue'),
          this.getAttribute('productId'),
          this.getAttribute('quantity'),
          myModal,
          null,
          callbackHandlers[this.getAttribute('callback')] || addTimeslot,
          this.getAttribute('timeslotId'),
          ids,
          this.getAttribute('selectedLocationId'),
          this.getAttribute('selectedEventDuration'),
        );
      });
    }

    this.shadowRoot.querySelector('.cancelButton').addEventListener('click', () => {
      $(myModal).modal('hide');
      this.dispatchEvent(new CustomEvent('closed', { bubbles: true }));
    });
  }
}


window.customElements.define('timeslot-select', ConfirmDialog);
