import $ from 'jquery';

$(() => {
  const $form = $('form#checkout-delivery-form');
  if (!$form.length) return;

  Array.from(document.getElementsByClassName('numberOfTables')).forEach((obj) => {
    const clothId = obj.dataset.cloths;
    const $numberOfTableClothsInput = document.getElementsByClassName(clothId)[0];

    const toggleTableClothsInput = () => {
      const numberOfTables = Number(obj.value);
      if (numberOfTables < 1) {
        $numberOfTableClothsInput.value = 0;
        $numberOfTableClothsInput.disabled = true;
      } else {
        $numberOfTableClothsInput.disabled = false;
      }
    };

    obj.addEventListener('change', toggleTableClothsInput);
    toggleTableClothsInput();
  });
});
