import $ from 'jquery';

$(() => {
  const $colorPicker = $('.cat-colour-picker');
  const $existingColour = $('.cat-colour-existing-value');
  const $imageGroup = $('#image-group');
  const $imageInput = $('.cat-image-input');
  const $resetButton = $('.cat-reset-colour');

  if (!$colorPicker.length) return;
  if (!$imageGroup.length) return;
  if (!$imageInput.length) return;
  if (!$resetButton.length) return;

  const toggleImageInput = (colorValue) => {
    if (colorValue && colorValue !== '') {
      $imageGroup.hide();
      $imageInput.attr('disabled', true);
    } else {
      $imageGroup.show();
      $imageInput.attr('disabled', false);
    }
  };

  toggleImageInput($existingColour.val());

  $colorPicker.on('input change', (e) => {
    const colorValue = $(e.target).val();
    toggleImageInput(colorValue);
  });

  $resetButton.on('click', () => {
    $colorPicker.val(null);
    toggleImageInput(null);
  });
});

$(() => {
  const $resetButton = $('.cat-reset-font-colour');
  const $colorPicker = $('.cat-font-colour-picker');

  if (!$resetButton.length) return;

  $resetButton.on('click', () => {
    $colorPicker.val(null);
  });
});

$(() => {
  $('.category').toArray().forEach((category) => {
    const $category = $(category);
    const colour = $category.data('colour');
    const fontColour = $category.data('font-colour');
    const pic = $category.data('pic');

    if (colour) {
      $category
        .css('background-color', colour);
    } else if (pic) {
      $category
        .css('background-image', `url('data:image/jpg;base64,${pic}')`)
        .css('background-size', 'cover')
        .css('background-position', 'center')
        .css('background-repeat', 'no-repeat');
    }

    if (fontColour) {
      $category.find('.text').css('color', fontColour);
    }
  });
});
