import $ from 'jquery';

$(() => {
  const $form = $('form.addProductToBasketForm');
  const $noThanksButton = $('button.forgotten-products-no-thanks');

  if (!$form.length) return;
  if (!$noThanksButton.length) return;

  $form.on('submit', () => {
    $noThanksButton.text('Continue');
  });
});
