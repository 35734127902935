import $ from 'jquery';

$(() => {
  const $basketSummary = $('#basketSummary');

  if (!$basketSummary.length) return;

  const adjustFixedElement = () => {
    const referenceHeader = $('.id7-page-header');
    if (!referenceHeader.length) return;
    const viewportWidth = $(window).width();
    const headerRect = referenceHeader[0].getBoundingClientRect();
    $basketSummary.css({
      /**
       * basketSummary is not inside header.
       * this visually align the sticky basket summary with the right edge of the header
       */
      // right: `${viewportWidth - headerRect.right}px`, // right does not seem to work in Firefox
      marginRight: `${viewportWidth - headerRect.right}px`,
    });
  };

  adjustFixedElement();
  $(window).on('resize', adjustFixedElement);
});

$(() => {
  $('.customer-delivery-instructions-button').click((event) => {
    const $target = $(event.currentTarget); // Get the current target using event.currentTarget
    const timeslotId = $target.data('timeslot-id');
    const productId = $target.data('product-id');

    const matchingModal = $('.customer-delivery-instructions-dialog').filter((index, element) => {
      const $element = $(element);
      return $element.data('timeslot-id') === timeslotId && $element.data('product-id') === productId;
    });

    $('body').append(matchingModal);
    $(matchingModal).modal('show');
  });
});
