import $ from 'jquery';
import { addDialogEvents } from './dialog';

function upsellAddToBasketEvent(e) {
  e.preventDefault();
  const form = e.target;
  const data = new FormData(form);
  const timeslot = document.getElementById('timeslotSelected')?.value;
  if (timeslot !== undefined) {
    data.append('timeslot', timeslot);
    data.append('upsell', 'true');
    fetch('/ajax/basket/add', {
      method: 'post',
      redirect: 'error',
      body: data,
    }).then((response) => {
      if (response.ok) {
        return response.text();
      }
      alert('There was a problem selecting this timeslot, please try again');
      return null;
    }).then((html) => {
      document.getElementById('basketSummary').innerHTML = html;
    }).catch((e1) => {
      console.log(e1);
      document.getElementById('genericModalMessage').innerHTML = 'Your basket has timed out, attempting to reclaim your chosen timeslots';
      $('#genericModal').modal('show');
      $('#genericModal').on('hide.bs.modal', () => {
        window.location.replace('/timeslot/expiry');
      });
    });
  } else {
    const addTimeslotButton = document.getElementById('addTimeslotButton');
    for (const pair of data) {
      addTimeslotButton.setAttribute(pair[0], pair[1]);
    }
    addTimeslotButton.shadowRoot.getElementById('triggerButton-confirmModal').click();
  }
}

function addToBasketEvent(e) {
  e.preventDefault();
  const form = e.target;
  const data = new FormData(form);
  const timeslot = document.getElementById('timeslotSelected')?.value;
  if (timeslot !== undefined) {
    data.append('timeslot', timeslot);
    fetch('/ajax/basket/add', {
      method: 'post',
      redirect: 'error',
      body: data,
    }).then((response) => {
      if (response.ok) {
        return response.text();
      }
      alert('There was a problem selecting this timeslot, please try again');
      return null;
    }).then((html) => {
      if (html) document.getElementById('basketSummary').innerHTML = html;
      const dialog = document.getElementById('basketDialog');
      if (dialog != null) {
        dialog.showModal();
        document.querySelector('#basketSummary [data-close-modal]').addEventListener('click', () => {
          dialog.close();
        });
      }

      const upsellDialog = document.getElementById('upsellDialog');
      if (upsellDialog != null) {
        const container = document.getElementById('upsellContainer');
        container.innerHTML = '';
        container.append(upsellDialog);
        upsellDialog.showModal();
        document.querySelector('#upsellDialog [data-close-modal]').addEventListener('click', () => {
          upsellDialog.close();
          upsellDialog.remove();
        });
        attachAddUpsellToBasketEvents();
        addDialogEvents();
      }
    }).catch((e1) => {
      console.log(e1);
      document.getElementById('genericModalMessage').innerHTML = 'Your basket has timed out, attempting to reclaim your chosen timeslots';
      $('#genericModal').modal('show');
      $('#genericModal').on('hide.bs.modal', () => {
        window.location.replace('/timeslot/expiry');
      });
    });
  } else {
    const addTimeslotButton = document.getElementById('addTimeslotButton');
    for (const pair of data) {
      addTimeslotButton.setAttribute(pair[0], pair[1]);
    }
    addTimeslotButton.shadowRoot.getElementById('triggerButton-confirmModal').click();
  }
}

function attachAddUpsellToBasketEvents() {
  $('.addUpsellProductToBasketForm').on('submit', upsellAddToBasketEvent);
}

function attachAddToBasketEvents() {
  $('.addProductToBasketForm').on('submit', addToBasketEvent);
}

$(() => {
  $('#timeslotSelected').on('change', () => {
    const data = new URLSearchParams();
    for (const pair of new FormData(document.querySelector('#timeslotSelectForm'))) {
      data.append(pair[0], pair[1]);
    }

    fetch('/ajax/basket/timeslot/set', {
      method: 'post',
      body: data,
    })
      .then((response) => {
        if (!response.ok) {
          alert('There was a problem selecting this timeslot, please try again');
        }
      })
      .catch((error) => {
        console.log(error);
        alert('There was a problem selecting this timeslot, please try again');
      });
  });

  attachAddToBasketEvents();
});
