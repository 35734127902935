import $ from 'jquery';
import '@universityofwarwick/id7/js/bootstrap-modal.jquery';
import VanillaCalendar from '@uvarov.frontend/vanilla-calendar';

const template = document.createElement('template');
template.innerHTML = `
<style>
    @import '/assets/style.css';
</style>
<a class="btn triggerButton" role="button" id="addTimeslotButton"></a>

<div class="modal fade" tabindex="-1" aria-labelledby="confirmDeleteModal" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><span class="title"></span></h5>
      </div>
      <div class="modal-body" id="timeslotBody">
        <div id="calendar"></div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn duplicateButton"></button>
        <button type="button" class="btn cancelButton"></button>
      </div>
    </div>
  </div>
</div>`;

class ConfirmDialog extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    const modalId = this.getAttribute('modalId') ?? 'confirmModal';
    const triggerButtonId = `triggerButton-${modalId}`;
    this.shadowRoot.querySelector('.modal').setAttribute('id', modalId);
    this.shadowRoot.querySelector('.triggerButton').setAttribute('id', triggerButtonId);
    this.shadowRoot.querySelector(`#${triggerButtonId}`).innerHTML = this.getAttribute('triggerButtonLabel') ?? 'Submit';
    this.shadowRoot.querySelector('.duplicateButton').innerHTML = this.getAttribute('triggerButtonLabel') ?? 'Submit';
    this.shadowRoot.querySelector('.cancelButton').innerHTML = this.getAttribute('cancelButtonLabel') ?? 'Close';
    this.shadowRoot.querySelector('.title').textContent = this.getAttribute('title') ?? 'Confirm';

    const triggerBtnClass = (this.getAttribute('triggerBtnClass') ?? 'btn-primary').split(' ');
    const confirmBtnClass = (this.getAttribute('confirmBtnClass') ?? 'btn-default').split(' ');
    const cancelBtnClass = (this.getAttribute('cancelBtnClass') ?? 'btn-default').split(' ');
    const url = this.getAttribute('url') ?? '';

    this.shadowRoot.querySelector('.triggerButton').classList.add(...triggerBtnClass);
    this.shadowRoot.querySelector('.cancelButton').classList.add(...cancelBtnClass);

    const myModal = $(this.shadowRoot.getElementById(modalId)).modal({ show: false });

    const numberOfMonths = parseInt(this.getAttribute('months'), 10) ?? 1;
    const today = this.getAttribute('today') ?? '';
    const startDate = this.getAttribute('startDate') ?? '';
    const endDate = this.getAttribute('endDate') ?? '';
    const disabledDates = this.getAttribute('disabledDates').split(',') ?? [''];
    let datesArray = [];

    this.shadowRoot.querySelector(`#${triggerButtonId}`).addEventListener('click', () => {
      $(myModal).modal('show');
      const calendar = new VanillaCalendar(this.shadowRoot.querySelector('#calendar'), {
        type: 'multiple',
        months: numberOfMonths,
        date: {
          today: new Date(today),
          min: startDate,
          max: endDate,
        },
        settings: {
          selection: {
            day: 'multiple',
          },
          visibility: {
            theme: 'light',
          },
          range: {
            disabled: disabledDates,
          },
        },
        actions: {
          clickDay(e, dates) {
            console.log(dates);
            datesArray = dates;
          },
        },
      });
      calendar.init();
    });

    this.shadowRoot.querySelector('.cancelButton').addEventListener('click', () => {
      $(myModal).modal('hide');
      this.dispatchEvent(new CustomEvent('closed', { bubbles: true }));
    });

    this.shadowRoot.querySelector('.duplicateButton').addEventListener('click', () => {
      const form = document.createElement('form');
      const csrf = document.createElement('input');
      const dates = document.createElement('input');
      const timeslotId = document.createElement('input');

      form.method = 'POST';
      form.action = url;

      csrf.name = this.getAttribute('csrfName');
      csrf.value = this.getAttribute('csrfValue');
      form.appendChild(csrf);

      timeslotId.name = 'timeslotId';
      timeslotId.value = this.getAttribute('timeslotId') ?? '';
      form.appendChild(timeslotId);

      dates.value = datesArray;
      dates.name = 'dates';
      form.appendChild(dates);

      document.body.appendChild(form);

      form.submit();
    });
  }
}


window.customElements.define('custom-calendars', ConfirmDialog);
