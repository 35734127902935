import $ from 'jquery';

$(() => {
  if ($('.product-info-page-block').length === 0) {
    return;
  }

  $('.small-image').on('click', (event) => {
    $('#large-image').attr(
      'src',
      $(event.currentTarget).attr('src'),
    );
  });

  $('#large-image').on('click', (event) => {
    event.stopPropagation();
    $('#enlarged-image').attr(
      'src',
      $('#large-image').attr('src'),
    );
    $('#largeImageModal').modal('show');
  });

  $('.kafoodle-allergen-info').toArray().forEach((dom) => {
    const productId = $(dom).data('product-id');

    fetch(`/allergen/product/${productId}`)
      .then(response => response.json())
      .then((allergens) => {
        const allergenNames = allergens.map(allergen => allergen.name);
        if (allergenNames.length === 0) {
          throw new Error('No allergens found');
        }
        $(dom).text(`Contains: ${allergenNames.join(', ')}`);
      })
      .catch(() => {
        $(dom).text('(Unable to get allergen information)');
      });
  });
});
