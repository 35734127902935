import $ from 'jquery';

export function addDialogEvents() {
  Array.from(document.querySelectorAll('.openDialog')).forEach((obj) => {
    obj.addEventListener('click', () => {
      const dialog = document.getElementById(obj.dataset.dialogId);
      dialog.showModal();

      Array.from(dialog.getElementsByClassName('closeButton')).forEach((btn) => {
        btn.addEventListener('click', () => {
          dialog.close();
        });
      });

      $('.multiSelect-pillbox').select2({
        dropdownParent: document.getElementById(obj.dataset.dialogId),
      });
    });
  });

  Array.from(document.querySelectorAll('dialog[data-open-modal]')).forEach((obj) => {
    obj.showModal();
  });
}

$(() => {
  addDialogEvents();
});
