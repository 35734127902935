import $ from 'jquery';

$(() => {
  $('.product-search-input .clear-btn').click(() => {
    $('#product-search-box').val('');
    $('form[role="search"]').submit();
  });

  $('.product-search-input .search-button').click(() => {
    $('form[role="search"]').submit();
  });
});
