/* eslint-env browser */
import './polyfills';
import './confirm';
import $ from 'jquery';
import * as flexiPicker from './flexi-picker';
import 'select2';
import './product-info';
import './product-search';
import './timeslot';
import './basket';
import './calendars';
import './online-payment';
import './checkout-delivery';
import './checkout-summary';
import './checkout-forgotten';
import './dialog';
import './categories';
import './basket-summary';
import './external-user-registration-invoice-address';

/**
 * Attach handlers to all elements inside $scope. All jQuery selects
 * must be scoped to $scope, and you should only call bindTo on content
 * that you know has not had handlers attached already (either a whole page
 * that's just been loaded, or a piece of HTML you've just loaded into the
 * document dynamically)
 */
function bindTo($scope) {
  $('[data-toggle="popover"]', $scope).popover();

  flexiPicker.bindTo($scope);
}

$(() => {
  const $html = $('html');

  // Apply to all content loaded non-AJAXically
  bindTo($('#main'));

  // Any selectors below should only be for things that we know won't be inserted into the
  // page after DOM ready.

  $('.multiSelect-pillbox').select2();

  $('.singleSelect-pillbox').select2({
    maximumSelectionLength: 1,
    allowClear: false,
  });

  $('.customerMessageModal').modal('show');

  // Dismiss popovers when clicking away
  function closePopover($popover) {
    const $creator = $popover.data('creator');
    if ($creator) {
      $creator.popover('hide');
    }
  }

  $('.back-btn').on('click', () => {
    window.history.back();
  });

  $('[data-toggle="tooltip"]').tooltip();

  $('.print-page-button').on('click', () => {
    window.print();
  });

  $('.monitorEnterKey').on('keyup', (evt) => {
    if (evt.keyCode === 13) {
      $(evt.target).parents('form').submit();
    }
  });
});
