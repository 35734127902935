import $ from 'jquery';

$(() => {
  const $invoiceCheckbox = $('#sameInvoiceAddress');
  const $invoicePanel = $('.external-user-registration-form .invoice-address-panel');

  const toggleInvoicePanel = (isChecked) => {
    if (isChecked) {
      $invoicePanel.slideUp(() => {
        $invoicePanel.find('input').prop('disabled', true);
      });
    } else {
      $invoicePanel.find('input').prop('disabled', false);
      $invoicePanel.slideDown();
    }
  };

  $invoiceCheckbox.on('change', (e) => {
    toggleInvoicePanel($(e.target).prop('checked'));
  });

  toggleInvoicePanel($invoiceCheckbox.prop('checked'));
});
