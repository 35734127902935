import $ from 'jquery';

$(() => {
  const $form = $('#online-payment-redirect-form');
  if ($form.length) {
    setTimeout(() => {
      $form.submit();
    }, 2000);
  }
});
